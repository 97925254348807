import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import {
  PersonEntry,
  CPDPersonEntry,
} from "frontend/contentful/schema/semantics";
import { useContentful } from "frontend/hooks/use-contentful";
import { createArticleRelatedPersonProps } from "./article-related-person.props";
import { RelatedPeopleProps } from "design-system/components/blocks/section-intro-text/section-intro-text";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { SectionIntroTextEntry } from "frontend/contentful/schema/blocks";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";

export const createRelatedPeopleProps = createContentfulProps<
  SectionIntroTextEntry,
  RelatedPeopleProps
>(({ entry }) => {
  const { getEntries, getEntry } = useContentful();

  const relatedPersonArray: RelatedPeopleProps = { people: [] };

  if (entry.fields.peopleHeading) {
    relatedPersonArray.title = (
      <RenderContentfulRichText document={entry.fields.peopleHeading} />
    );
  } else {
    relatedPersonArray.title = "Related People";
  }

  getEntries(entry.fields.people).forEach((person) => {
    if (isContentType<PersonEntry>("person", person)) {
      const relatedPersonProps = createArticleRelatedPersonProps(person);
      if (relatedPersonProps) {
        relatedPersonArray.people?.push(relatedPersonProps);
      }
    }
    if (isContentType<CPDPersonEntry>("cpdPerson", person)) {
      const cpdPerson = getEntry(person.fields.person);
      if (cpdPerson) {
        cpdPerson.fields.displayName = person.fields.displayName;
        cpdPerson.fields.image = person.fields.thumbnail;

        const relatedPersonProps = createArticleRelatedPersonProps(cpdPerson);
        if (relatedPersonProps) {
          relatedPersonArray.people?.push(relatedPersonProps);
        }
      }
    }
  });
  return relatedPersonArray;
});
