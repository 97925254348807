import {
  PersonEntry,
  PersonWrapperEntry,
} from "frontend/contentful/schema/semantics";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { RelatedPerson } from "design-system/components/pages/article-page/article-related-people/article-related-people";
import { createPersonImageProps } from "../primitives/media-asset.props";
import { useContentful } from "frontend/hooks/use-contentful";
import { isContentType } from "frontend/contentful/lib/is-content-type";

export const createArticleRelatedPersonProps = createContentfulProps<
  PersonEntry | PersonWrapperEntry,
  RelatedPerson
>(({ entry, createEditAttributes }) => {
  const { getPersonLink, getPersonName, getEntry } = useContentful();

  let personEntry: PersonEntry | undefined;

  if (isContentType<PersonWrapperEntry>("personWrapper", entry)) {
    personEntry = getEntry(entry.fields.person);
  } else {
    personEntry = entry;
  }

  const props: RelatedPerson = {
    name: getPersonName(entry),
    title: personEntry?.fields.jobTitle || "",
    link: getPersonLink(entry),
    portrait: createPersonImageProps(entry),
    email: personEntry?.fields.email,
    editAttributes: {
      name: createEditAttributes({ entry: personEntry, fieldId: "firstName" }),
      jobTitle: createEditAttributes({
        entry: personEntry,
        fieldId: "jobTitle",
      }),
    },
  };
  return props;
});
