import "./article-related-people.scss";

import { MediaAssetProps } from "design-system/components/primitives/media-asset/media-asset";
import { EditAttributes } from "design-system/types/types";
import { ArticleRelatedPeopleItem } from "./article-related-people-item";

export interface RelatedPerson {
  name: string;
  title: string;
  link?: string;
  email?: string;
  portrait?: MediaAssetProps;

  editAttributes?: {
    name?: EditAttributes;
    jobTitle?: EditAttributes;
  };
}

export interface ArticleRelatedPeopleProps {
  people?: Array<RelatedPerson>;
}

/**
 * This component is for displaying a list of related people near the footer of an article page.
 *
 * ## See it in use on...
 * - An [article page](/story/example-pages-article-pages-big-art-topper--story)
 *
 * - **`id: CS-002-000-00`**
 * - **`data-region: cs__related-people`**
 */
export function ArticleRelatedPeople({ people }: ArticleRelatedPeopleProps) {
  if (!people) return;
  return (
    <ul className="hbs-article-related-people" data-region="cs__related-people">
      {people.map((person, i) => (
        <ArticleRelatedPeopleItem key={i} {...person} />
      ))}
    </ul>
  );
}
