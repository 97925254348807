import { RelatedPerson } from "./article-related-people";
import { MediaAsset } from "design-system/components/primitives/media-asset/media-asset";
import { Link } from "design-system/components/primitives/link/link";

export function ArticleRelatedPeopleItem(person: RelatedPerson) {
  return (
    <li className="hbs-article-related-people__item">
      {person.link ? (
        <Link className="hbs-article-related-people__link" href={person.link}>
          <ArticleRelatedPeopleItemContent {...person} />
        </Link>
      ) : (
        <ArticleRelatedPeopleItemContent {...person} />
      )}
    </li>
  );
}

function ArticleRelatedPeopleItemContent(person: RelatedPerson) {
  if (person.portrait?.image) {
    person.portrait.image.mobileWidth = 130;
  }

  return (
    <div className="hbs-article-related-people__content">
      {person.portrait && (
        <div className="hbs-article-related-people__portrait">
          <MediaAsset {...person.portrait} aspectRatio="1/1" />
        </div>
      )}

      <h3
        className="hbs-article-related-people__member-name"
        {...person.editAttributes?.name}
      >
        {person.name}
      </h3>

      <span
        className="hbs-article-related-people__member-title"
        {...person.editAttributes?.jobTitle}
      >
        {person.title}
      </span>
    </div>
  );
}
