import "./section-intro-text.scss";

import cn from "clsx";
import { ReactNode } from "react";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import { Alignment, EditAttributes } from "design-system/types/types";
import {
  ArticleRelatedPeople,
  ArticleRelatedPeopleProps,
} from "design-system/components/pages/article-page/article-related-people/article-related-people";

export interface RelatedPeopleProps extends ArticleRelatedPeopleProps {
  title?: ReactNode;
}

export interface SectionIntroTextProps {
  asset?: MediaAssetProps;
  relatedPeople?: RelatedPeopleProps;
  intro?: ReactNode;
  text?: ReactNode;
  align?: Alignment;

  editAttributes?: {
    intro?: EditAttributes;
    text?: EditAttributes;
  };
}

/**
 * ## See it in use on...
 * - The [MBA Entrepreneurship page](/story/example-pages-landing-pages-mba-entrepreneurship--story)
 *
 * - **`id: CB-013-000-00`**
 * - **`data-region: cb__section-intro-text`**
 */
export function SectionIntroText({
  asset,
  relatedPeople,
  intro,
  text,
  align = "full",
  editAttributes,
}: SectionIntroTextProps) {
  const wrapperClass = cn(
    align && `hbs-global-align-${align}`,
    "hbs-component--section-intro-text",
  );
  const blockClass = cn(
    "hbs-section-intro-text",
    asset && "hbs-section-intro-text--has-asset",
  );

  return (
    <div className={wrapperClass} data-region="cb__section-intro-text">
      <div className={blockClass}>
        <div className="hbs-section-intro-text__content">
          {intro && (
            <div
              {...editAttributes?.intro}
              className="hbs-section-intro-text__intro"
            >
              {intro}
            </div>
          )}

          {text && (
            <div
              {...editAttributes?.text}
              className="hbs-section-intro-text__text"
            >
              {text}
            </div>
          )}
        </div>

        {asset && (
          <div className="hbs-section-intro-text__media">
            <div className="hbs-section-intro-text__media-wrapper">
              <MediaAsset {...asset} />
            </div>
          </div>
        )}

        {!asset && relatedPeople && (
          <div className="hbs-section-intro-text__related-people">
            <h2 className="hbs-section-intro-text__related-people-title">
              {relatedPeople.title}
            </h2>

            <ArticleRelatedPeople {...relatedPeople} />
          </div>
        )}
      </div>
    </div>
  );
}
