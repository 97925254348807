import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { SectionIntroTextEntry } from "frontend/contentful/schema/blocks";
import { SectionIntroText } from "design-system/components/blocks/section-intro-text/section-intro-text";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createMediaAssetProps } from "../primitives/media-asset.props";
import { Alignment } from "design-system/types/types";

import { createRelatedPeopleProps } from "../primitives/section-intro-related-people.props";

interface SectionIntroTextOptions {
  alignment?: Alignment;
}

export const ContentfulSectionIntroText = createContentfulComponent<
  SectionIntroTextEntry,
  SectionIntroTextOptions
>(({ entry, options, createEditAttributes }) => {
  const asset = createMediaAssetProps(entry.fields.media);
  if (asset?.kaltura) {
    asset.kaltura.withModal = true;
  }
  return (
    <SectionIntroText
      intro={
        entry.fields.intro && (
          <RenderContentfulRichText document={entry.fields.intro} />
        )
      }
      text={
        entry.fields.bodyText && (
          <RenderContentfulRichText document={entry.fields.bodyText} />
        )
      }
      asset={asset}
      relatedPeople={
        entry.fields.people ? createRelatedPeopleProps(entry) : undefined
      }
      align={options?.alignment}
      editAttributes={{
        intro: createEditAttributes({ entry, fieldId: "intro" }),
        text: createEditAttributes({ entry, fieldId: "bodyText" }),
      }}
    />
  );
});
